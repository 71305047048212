body {
  margin: 0;
  padding: 0;
  max-width: 100%;
  overscroll-behavior: none;
}

div#root {
  overflow: hidden;
}
